<template>
	<div class="container">
		<top-header></top-header>
		<div class="datalake">
			<div class="width-1300">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>全球产品大数据湖</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="title">
					<p>全球产品大数据湖</p>
					<p>Global product big data Lake</p>
				</div>
				<div class="item">
					<div class="left">
						<div class="left-item">
							<router-link to="/literature"><img src="../../../static/images/icon/datalake1.png" ></router-link>
						</div>
						<div class="left-item">
							<router-link to="/supplier"><img src="../../../static/images/icon/datalake2.png" ></router-link>
						</div>
						<div class="left-item">
							<router-link to="/case"><img src="../../../static/images/icon/datalake3.png" ></router-link>
						</div>
					</div>
					<!-- 右区域 -->
					<div class="right">
						<img src="../../../static/images/icon/datalake4.png" >
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	export default {
		components: {
			topHeader,
			bottomFooter
		},
		data() {
			return {
				
			}
		},
		created() {
			
		},
		methods: {
			
		}
	}
</script>

<style lang="scss">
	.datalake{
		margin-top: 60px;
		font-size: 16px;
		padding-top: 20px;
		min-height: 87.8vh;
		box-sizing: border-box;
		.item{
			text-align: center;
			@media screen and(min-width:320px) and (max-width:767px) {
				text-align: right;
				margin: 0 -10px;
			}
			.left{
				display: inline-block;
				vertical-align: middle;
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 50%;
				}
			}
			.left-item{
				width: 355px;
				// border: 1px solid;
				position: relative;
				margin-bottom: 20px;
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 100%;
				}
				img{
					position: relative;
					width: 90%;
					z-index: 2;
				}
				&:nth-child(1){
					margin-right: 40px;
					&::after{
						content: '';
						position: absolute;
						top: 50%;
						margin-top: -5px;
						height: 5px;
						right: -220px;
						width: 250px;
						z-index: 0;
						background-color: #39C2EA;
						@media screen and(min-width:320px) and (max-width:767px) {
							width: 50%;
							right: -48%;
						}
					}
				}
				&:nth-child(2){
					&::after{
						content: '';
						position: absolute;
						top: 50%;
						margin-top: -5px;
						height: 5px;
						right: -220px;
						width: 250px;
						z-index: 0;
						background-color: #39C2EA;
						@media screen and(min-width:320px) and (max-width:767px) {
							width: 50%;
							right: -48%;
						}
					}
				}
				&:nth-child(3){
					&::after{
						content: '';
						position: absolute;
						top: 50%;
						margin-top: -5px;
						height: 5px;
						right: -220px;
						width: 250px;
						z-index: 0;
						background-color: #39C2EA;
						@media screen and(min-width:320px) and (max-width:767px) {
							width: 50%;
							right: -48%;
						}
					}
				}
			}
			.right{
				display: inline-block;
				vertical-align: middle;
				width: 300px;
				@media screen and(min-width:320px) and (max-width:767px) {
					width: 50%;
				}
				img{
					position: relative;
					width: 90%;
					z-index: 2;
				}
			}
		}
		.title {
			font-size: 30px;
			letter-spacing: 10px;
			text-align: center;
			margin-bottom: 50px;
			margin-top: 60px;
		
			p {
				padding-left: 10px;
				box-sizing: border-box;
		
				&:last-child {
					padding-left: 0;
					font-size: 18px;
					letter-spacing: 0px;
				}
			}
			@media screen and(min-width:320px) and (max-width:767px) {
				font-size: 24px;
				letter-spacing: 5px;
				p{
					&:last-child{
						font-size: 14px;
					}
				}
			}
		}
	}
</style>
